import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import OrderReport from './subcomponents/OrderReport';
import ActivationReport  from './subcomponents/ActivationReport';
import parse from 'html-react-parser';
import { Col } from 'react-bootstrap';
import RepCommissionReport from './subcomponents/RepCommissionReport';
import OrderDashboard from './subcomponents/OrderDashboard'
import CommissionDashboard from './subcomponents/CommissionDashboard'

const WSReport = ({toolData}) => {

    const [activePartnerSubscription, setActivePartnerSubscription] = useState(false);

    const [expectedComission, setExpectedComission] = useState([])
    const [orderReport, setOrderReport] = useState(<OrderReport/>);
    const [activationReport, setActivationReport] = useState(false);
    const [repCommisionReport, setRepCommisionReport] = useState(false);
    const [orderDashboard, setOrderDashboard] = useState(false);
    const [commissionDashboard, setcommissionDashboard] = useState(false);
    const { userSession } = useContext(AccountContext)

    useEffect(() => {

        if (Object.entries(toolData).length > 0)
        {
            toolData.subscriptions.forEach(subscription => {
                if (subscription.partner_id === Number(userSession['custom:partnerId']) && subscription.is_active)
                {
                    setActivePartnerSubscription(true)
                }
            })

        }

        checkUser()
    },[]);

    const checkUser = () => {

        if (userSession.email == 'ckolar@wirelesssupport.com' || userSession.email.includes('@nexnow.net'))
            setExpectedComission(true)
        else
            setExpectedComission(false)
    }

    const handleReport = (report) => {

        setActivationReport(false)
        setRepCommisionReport(false)
        setOrderDashboard(false)
        setOrderReport(false)
        setcommissionDashboard(false)
        
        if (report == 'order'){
            setOrderReport(<OrderReport/>)
        }
        else if(report == 'activation'){
            setActivationReport(<ActivationReport/>)
        }
        else if(report == 'repCommision'){
            setRepCommisionReport(<RepCommissionReport/>)
        }
        else if(report == 'orderDashboard'){
            setOrderDashboard(<OrderDashboard/>)
        }
        else if(report == 'commissionDashboard'){
            setcommissionDashboard(<CommissionDashboard/>)
        }
    }

    return(
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <Row>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description:</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                        </Row>
                        <ButtonBuilder label='Order Report' className = 'm-2' color='secondary' onClick={() => handleReport('order')} />
                        <ButtonBuilder label='Activation Report' className = 'm-2' color='secondary' onClick={() => handleReport('activation')} />
                        <ButtonBuilder label='Order Dashboard' className = 'm-2' color='secondary' onClick={() => handleReport('orderDashboard')} />
                        <ButtonBuilder label='Commission Dashboard' className = 'm-2' color='secondary' onClick={() => handleReport('commissionDashboard')} />
                        {expectedComission ? <ButtonBuilder label='Rep Commission Report' className = 'm-2' color='secondary' onClick={() => handleReport('repCommision')} />: ''}
                        {orderReport}
                        {activationReport}
                        {repCommisionReport}
                        {orderDashboard}
                        {commissionDashboard}   
                    </CardBody>
                </Card>
            </Col>
        </Fragment>   
    )
};

export default WSReport;