import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, uploadPresignedFile } from '../../../../../../GlobalComponents/Helpers';
import {  Container, Row, Card, CardBody, Form, Col } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Navigate,useParams } from 'react-router-dom';

const ProductPricingUpdaterJob = ({jobData, getJobData}) => {

    let { toolId, jobId } = useParams();

    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [isComplete, setIsComplete] = React.useState(false);
    const [renderPage, setRenderPage] = useState(false);
    const [jobErrorData, setJobErrorData] = useState([]);
    const [jobStatus, setJobStatus] = useState([]);
    const [jobMessage, setJobMessage] = useState([]);
    
    const getJobErrorData = () => {
        
        ApiRequestBuilder('toolsToolIdJobsJobIdDatasetsDatasetNameGet', { toolId, jobId, datasetName: toolId })
            .then(function(results) {
                setJobErrorData(results.data)
            }
        )
    }

    const getJobStatus = async() => {
        
        const results = await ApiRequestBuilder('jobsJobIdStatusGet', { jobId })

        if (results.data == '')
        {
            setJobStatus('Awaiting Job');    
        }
        else
        {
            setJobStatus(results.data['status']);
            setJobMessage(results.data['message'])
        }
        return results.data['status'];
        
    }

    const handleDocumentUpload = (formData) => {

        if (formData['connectionFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            var fileId = buildRandomId(10);

            uploadPresignedFile(formData['connectionFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: formData['connectionFileToUpload'][0].name,
                type: 'jobFile',
                metadata: {
                    jobid: jobData.id,
                    fileid : fileId,
                    fileprefix: 'Product Updates: '
                }
            }, () => { handleJobAction('product-price-updates', null, { fileName: formData['connectionFileToUpload'][0].name, companyId: formData.companyId })}, { timeout: '10000' })            
        }
    }
    
    const handleJobAction = (action, myCallback = null, formData = null) => {
        
        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { ...formData })
            .then(function(results) {
                ToastBuilder('success', 'Action Started Successfully')
                myCallback ? myCallback(results) : null
            }
        )
    }

    useEffect(() => {
        let interval;
    
        const startPolling = async () => {
            interval = setInterval(async () => {
    
                // Fetch the latest job status
                const status = await getJobStatus(); // Make sure this returns fresh data
                
                // Stop polling once a "Completed" status is returned
                if (status === "Completed") {
                    console.log('Polling stopped: Job is Completed');
                    clearInterval(interval); // Stop polling
                    await getJobErrorData(); // Fetch additional data
                    setIsComplete(true); // Update completion state if needed
                }
            }, 5000); // Check every 5 seconds
        };
    
        startPolling(); // Start polling when the effect runs
    
        return () => {
            // Cleanup interval on unmount
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            getJobStatus()            
            getJobErrorData()
            setRenderPage(true)
        }

    }, []);

    if (renderPage)
    { 
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                {jobData && jobData.destination_connection ? (<p><strong>Destination: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}
                                {jobStatus ? (<p><strong>Job Status: </strong>{' ' + jobStatus}</p>) : ''}
                                {jobMessage && jobMessage != '' ? (<p><strong>Job Message: </strong>{' ' + jobMessage}</p>) : ''}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Actions</h4>
                                <br/>
                                <ModalButtonBuilder title='Update Prices' className='btn-secondary m-1' size='lg'
                                    body={
                                        <Fragment>
                                            <p>Please a CSV containing the products and prices you'd like to update before clicking "Submit."</p>
                                            <br/>
                                            <Form id='update-prices-form' onSubmit={handleSubmit(handleDocumentUpload)}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Choose a File',
                                                            size: 12,
                                                            name: 'connectionFileToUpload',
                                                            type: 'file',
                                                        }
                                                    ]}
                                                />
                                            </Form>
                                        </Fragment>
                                    }
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>  
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                { jobErrorData != null ?
                                <TableBuilder
                                    subHeader={true}
                                    data = {jobErrorData}
                                    processing = {false}
                                    title = 'Pricing Update Errors'
                                    sortAsc = {true}
                                    columns = {[
                                        {
                                            name: 'Row #',
                                            selector: (row) => row.row_num,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Error',
                                            selector: (row) => row.error,
                                            sortable: true,
                                            center: true,
                                            width: "40%"
                                        },
                                        {
                                            name: 'Product ID',
                                            selector: (row) => row.product_id,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Price',
                                            selector: (row) => row.new_price,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Cost',
                                            selector: (row) => row.new_cost,
                                            sortable: true,
                                            center: true,
                                        }
                                    ]}
                                /> : null}
                            </CardBody>
                        </Card>
                    </Container>
                </Row>   
            </Fragment>
        )
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default ProductPricingUpdaterJob;