import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import Charts from 'react-apexcharts';
import { Col } from 'react-bootstrap';

const OrderDashboard = () => {

    const [previousMonthCarriers, setPerviousMonthCarriers] = useState([])
    const [previousMonthSalesRep, setPerviousMonthSalesRep] = useState([])
    const [previousMonthClientStatus, setPerviousMonthClientStatus] = useState([])
    const [monthCarriers, setMonthCarriers] = useState([])
    const [monthSalesRep, setMonthSalesRep] = useState([])
    const [monthClientStatus, setMonthClientStatus] = useState([])
    const [commissonChart, setCommissionChart] = useState(false)
    const [repCommissionChart, setRepCommissionChart] = useState(false)
    
    //Get Dates
    let now = new Date();
    let firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    let lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    let firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDayThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Convert to Unix timestamp
    let firstDayLastMonthUnixTimestamp = Math.floor(firstDayLastMonth.getTime() / 1000);
    let lastDayLastMonthUnixTimestamp = Math.floor(lastDayLastMonth.getTime() / 1000);
    let firstDayThisMonthUnixTimestamp = Math.floor(firstDayThisMonth.getTime() / 1000);
    let lastDayThisMonthUnixTimestamp = Math.floor(lastDayThisMonth.getTime() / 1000);

    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    const currentMonth = monthNames[now.getMonth()];
    const previousMonth = monthNames[now.getMonth() -1];

    const saleRepChartCall = () =>{
        
        ApiRequestBuilder('oepOrdersSalesrepsCommissionChartGet').then(function (results) {
            if (results.data != {} )
            {
                let orderStatus = {};
                let chartData = [];
                let names = [];
                
                for (let rep in results.data){
                    names.push(rep)

                    for(let status in results.data[rep]){

                        if (status in orderStatus){
                            orderStatus[status].push(results.data[rep][status])
                        }
                        else{
                            orderStatus[status] = [results.data[rep][status]]
                        }
                    }
                }

                Object.entries(orderStatus).forEach(([key, value]) => {
                    //To display on the chart, it needs to be in an array of objects with the keys 'name' and 'data'
                    chartData.push({
                        'name': key,
                        'data': value
                    })
                })

                setRepCommissionChart({
                    options: {
                        chart: {
                        type: 'bar',
                        stacked: true,
                        },
                        plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                        },
                        xaxis: {
                        categories: names,
                        },
                        legend: {
                        position: 'top',
                        },
                        fill: {
                        opacity: 1,
                        },
                    },
                    series: chartData
                });
            }
        })
    };

    const commissionChartCall = () =>{
        ApiRequestBuilder('oepOrdersCarriersGet').then(function (results) {
            if (results.data && results.data.length > 0)
            {
                setCommissionChart({
                    series: [{
                        data: [results.data[0].created_commission, results.data[0].submitted_commission, results.data[0].closed_commission]
                    }],
                    plotOptions: {
                        bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                        },
                        xaxis: {
                            categories: ['Created', 'Submitted', 'Closed'],
                        },
                        chart: {
                            type: 'bar',
                            height: 350
                        },
                    },
                    dataLabels: {
                        enabled: false
                    }
                })
            }
            else ToastBuilder('info', 'Unable to Create Chart')
        })
    };

    const dashboardOrders = (dates) => {
        ApiRequestBuilder('oepOrdersDashboardPost', null, dates)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    var carrier = [];
                    var salesRep = [];
                    var clientStatus = [];

                    for(let order in results.data){
                        if (results.data[order]['category'] == 'Carrier') carrier.push(results.data[order])
                        else if (results.data[order].category == 'Client Status') clientStatus.push(results.data[order])
                        else if (results.data[order].category == 'Sales Rep') salesRep.push(results.data[order])
                    }
                    
                    if (dates.previousMonth == true){
                        setPerviousMonthCarriers(carrier);
                        setPerviousMonthClientStatus(clientStatus);
                        setPerviousMonthSalesRep(salesRep);
                    }
                    else{
                        setMonthCarriers(carrier);
                        setMonthClientStatus(clientStatus);
                        setMonthSalesRep(salesRep);
                    }
                }
            }
        )
    }

    useEffect(() => {
        commissionChartCall()
        saleRepChartCall()
        dashboardOrders({"searchFrom":firstDayLastMonthUnixTimestamp, "searchTo": lastDayLastMonthUnixTimestamp, "previousMonth": true})
        dashboardOrders({"searchFrom":firstDayThisMonthUnixTimestamp, "searchTo": lastDayThisMonthUnixTimestamp, "previousMonth": false})
    },[]);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <h4>Order Dashboard</h4>
                    { commissonChart != false ? 
                        <Col>
                            <Charts options={commissonChart.plotOptions} series={commissonChart.series} type="bar" height={350} />
                        </Col>
                    : null}
                </CardBody>

                <CardBody>
                    <h4>{currentMonth} Sales Rep Dashboard</h4>
                    { repCommissionChart != false ? 
                        <Col>
                            <Charts options={repCommissionChart.options} series={repCommissionChart.series} type="bar" height={350} />
                        </Col>
                    : null}
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <TableBuilder
                        data = {previousMonthCarriers}
                        processing = {false}
                        subHeader={true}
                        title={previousMonth +" Carrier Orders"}
                        columns = {[
                            {
                                name: 'Carrier',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />

                    <TableBuilder
                        data = {previousMonthSalesRep}
                        processing = {false}
                        subHeader={true}
                        title={previousMonth +" Sales Rep Orders"}
                        columns = {[
                            {
                                name: 'Sales Rep',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />

                    <TableBuilder
                        data = {previousMonthClientStatus}
                        processing = {false}
                        subHeader={true}
                        title={previousMonth + " Client Status Orders"}
                        columns = {[
                            {
                                name: 'Client Status',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />
                </CardBody>
            </Card>
            
            <Card>
                <CardBody>
                    <TableBuilder
                        data = {monthCarriers}
                        processing = {false}
                        subHeader={true}
                        title= {currentMonth + " Carrier Orders"}
                        columns = {[
                            {
                                name: 'Carrier',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />

                    <TableBuilder
                        data = {monthSalesRep}
                        processing = {false}
                        subHeader={true}
                        title= {currentMonth +" Sales Rep Orders"}
                        columns = {[
                            {
                                name: 'Sales Rep',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />

                    <TableBuilder
                        data = {monthClientStatus}
                        processing = {false}
                        subHeader={true}
                        title={currentMonth +" Client Status Orders"}
                        columns = {[
                            {
                                name: 'Client Status',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
};

export default OrderDashboard;