import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import parse from 'html-react-parser'
import { Col } from 'react-bootstrap';

const OrderReport = () => {

    const { userSession } = useContext(AccountContext);

    const [activePartnerSubscription, setActivePartnerSubscription] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [expectedComission, setExpectedComission] = useState([])
    const [salesReps, setSalesReps] = useState([]);
    const [searchQuery, setSearchQuery] = useState({});
    const [searchResults, setSearchResults] = useState([]);

    const handleCSVDownload = (data, fileName) => {
        
        if (data.data)
        {
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'csv'});
            element.href = URL.createObjectURL(file);
            element.download = fileName + ".csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        else
        {
            ToastBuilder('error', 'We are unable to provide you with the export')
        }
    }

    const searchOrders = (formData) => {
        
        setSearchQuery({})
        setSearchResults([])

        ApiRequestBuilder('oepOrdersSearchPost', null, formData)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    setSearchQuery(formData) // We'll store the query to pass to the POST handler if the user wants an export
                    setSearchResults(results.data)
                    ToastBuilder('success', 'Record Search Successful')
                } else ToastBuilder('info', 'No Records Matching Search')

            }
        )
    } 

    const salesRepCall = () => {
        ApiRequestBuilder('oepOrdersSalesrepsGet').then(function (reps) {
            setSalesReps([''].concat(reps.data))
        })
    }

    const checkUser = () => {
        if (userSession.email == 'ckolar@wirelesssupport.com' || userSession.email.includes('@nexnow.net'))
            setExpectedComission(false)
        else{
            setExpectedComission(true)
        }
    }

    const handleExport = () => {
        ApiRequestBuilder('oepOrdersSearchExportPost', null, searchQuery).then(function (results) {
            if (results.data && results.data.length > 0)
            {
                handleCSVDownload(results, 'order_search_export')
            } else ToastBuilder('info', 'No Records Matching Search')
        })
    }

    const getDate = (timestamp) => {
        if (typeof timestamp === 'number' && timestamp > 0) {
            const date = new Date(timestamp * 1000);
            return date.toISOString().split('T')[0];
        }
    }

    useEffect(() => {
        checkUser()
        salesRepCall()
    },[]);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <h4>Order Report</h4>
                    { salesReps.length > 0 ? 
                        <Form id='search-orders-form' className='card' onSubmit={handleSubmit((data) => searchOrders(data))}>
                            <CardBody>
                                <Row>
                                <RegisteredInputsBuilder
                                    registrator={register}
                                    registratorErrors={errors}
                                    inputs={[
                                        {
                                            label: 'From',
                                            type: 'date', 
                                            name: 'searchFrom',
                                            helpText: 'Activation Date Start'
                                        },
                                        {
                                            label: 'To',
                                            type: 'date', 
                                            name: 'searchTo',
                                            helpText: 'Activation Date End'
                                        },
                                        {
                                            label: 'Sales Rep',
                                            type: 'select',
                                            required: false,
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                            name: 'salesRep'
                                        },
                                        {
                                            label: 'Company',
                                            name: 'company',
                                        },
                                        {
                                            label: 'Carrier',
                                            type: 'select',
                                            required: false,
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
                                            name: 'carrier'
                                        },
                                        {
                                            label: 'Order Status', 
                                            type: 'select',
                                            required: false,
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'Created', 'Submitted', 'Closed'], { labelIsValue: true })}/>,
                                            name: 'order_status'
                                        }
                                    ]}/>
                                
                                </Row>
                            </CardBody>
                            <CardFooter className='text-end'>
                                <ButtonBuilder form='search-orders-form' label='Search' className='btn-primary m-2' />
                                <ButtonBuilder form='' label='Reset' className='btn-secondary m-1' onClick={() => { 
                                    setValue('salesRep', '')
                                    setValue('searchFrom', '')
                                    setValue('company', '')
                                    setValue('carrier', '')
                                    setValue('status', '')
                                    setValue('searchTo', '')
                                    setValue('order_status', '')
                                }}/>
                            </CardFooter>
                        </Form> : null}
                </CardBody>
            </Card>
            { searchResults.length > 0 ? 
                <Card>
                    <CardBody>
                        <TableBuilder
                            data = {searchResults}
                            processing = {false}
                            subHeader={true}
                            title='Search Results'
                            columnsToSearch = {['order_number', 'company_name', 'carrier', 'sales_rep', 'order_status']}
                            actions={
                                <Fragment>
                                    <ButtonBuilder label={'Export'} className='btn-primary m-1' onClick={() => handleExport()}/>
                                </Fragment>
                            }
                            columns = {[
                                {
                                     name: 'Order',
                                     selector: (row) => row.order_number,
                                     sortable: true,
                                     center: true,
                                     maxWidth: '3%'
                                },
                                {
                                    name: 'Company',
                                    selector: (row) => row.company_name,
                                    sortable: true
                                },
                                {
                                    name: 'Carrier',
                                    selector: (row) => row.carrier,
                                    sortable: true
                                },
                                {
                                    name: 'Sales Rep',
                                    selector: (row) => row.sales_rep,
                                    sortable: true
                                },
                                {
                                    name: 'Order Status',
                                    selector: (row) => row.order_status ,
                                    sortable: true
                                },
                                {
                                    name: '# of Lines',
                                    selector: (row) => row.total_line_items,
                                    sortable: true
                                },
                                {
                                    name: 'Client Status',
                                    selector: (row) => row.client_status,
                                    sortable: true
                                },
                                {
                                    name: 'pg_or_pt',
                                    selector: (row) => row.pg_or_pt,
                                    sortable: true
                                },
                                {
                                    name: 'Commission Amount',
                                    selector: (row) =>row.commission_amount,
                                    sortable: true
                                },
                                {
                                    name: 'Activation Date',
                                    selector: (row) => getDate(row.activation_date),
                                    sortable: true
                                },
                                {
                                    name: 'Creation Date',
                                    selector: (row) => getDate(row.creation_date),
                                    sortable: true
                                },
                                {
                                    name: 'Submission Date',
                                    selector: (row) => getDate(row.submission_date),
                                    sortable: true
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
        : null}
        </Fragment>
    )
};

export default OrderReport;