import React, { Fragment, useContext, useState, useEffect } from 'react';
import TableBuilder, { FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, sortArrayByKey } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import parse from 'html-react-parser'

const ProductPricingUpdater = ({toolData, jobData, connectionData, handleJobCreate}) => {

    const { userSession } = useContext(AccountContext);
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    var requiredPermissions = [
        {
            module: 'Admin',
            permission: 'ALL',
            levels: 'Add, Edit, Delete, Inquire'
        }
    ]

    const [activePartnerSubscription, setActivePartnerSubscription] = useState(false);
    
    useEffect(() => {
        if (Object.entries(toolData).length > 0)
        {
            console.log(toolData)
            toolData.subscriptions.forEach(subscription => {
                if (subscription.partner_id === Number(userSession['custom:partnerId']) && subscription.is_active)
                {
                    setActivePartnerSubscription(true)
                }
            })
        }
    })

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                            <br/>
                            <h5>Permissions Required</h5>
                            <TableBuilder
                                data={requiredPermissions}
                                processing={false}
                                title = ''
                                columns = {[
                                    {
                                        name: 'Module',
                                        selector: (row) => row.module,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Permission',
                                        selector: (row) => row.permission,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Levels',
                                        selector: (row) => row.levels,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {activePartnerSubscription ? jobData : []}
                                processing = {false}
                                title = 'Jobs'
                                actions = {
                                    activePartnerSubscription ? connectionData.length > 0 ? 
                                        <ModalButtonBuilder title='Create Job' className='btn-secondary'
                                            body = {
                                                <Form id='create-job-form' onSubmit={handleSubmit((data) => handleJobCreate(data))}>
                                                    <RegisteredInputsBuilder
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'Name',
                                                                size: 12,
                                                                name: 'name'
                                                            },
                                                            {
                                                                label: 'Destination Connection',
                                                                type: 'select',
                                                                size: 12,
                                                                name: 'destinationConnection',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData.filter(connection => connection.type !== 'csv'), { label: 'name', value:'id'})}/>,
                                                            }
                                                        ]}/>
                                                </Form>
                                            }
                                        /> : 
                                        <Fragment>
                                            <p><strong>You will need to create a connection before creating a job for this tool</strong> - </p>
                                            <ButtonBuilder label = 'Create Connection' onClick={() => window.location.href = process.env.PUBLIC_URL + '/connections'}/>
                                        </Fragment>: 
                                        <Fragment>
                                            <p><strong>You will need to subscribe to this tool before creating a job</strong></p>
                                        </Fragment>
                                }
                                columns = {[
                                    {
                                        name: 'Job #',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%'
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Active',
                                        selector: (row) => row.is_active ? 'Yes' : 'No',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Details',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/` + toolData.route + '/jobs/' + row.id}>{'View / Edit'}</Link>,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    )
}

export default ProductPricingUpdater;