import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, CardFooter} from 'reactstrap'
import Charts from 'react-apexcharts';
import { Col } from 'react-bootstrap';

const CommissionDashboard = () => {

    const [commissonChart, setCommissionChart] = useState([])
    const [outStandingCommission, setOutStandingCommission] = useState([])


    const commissionChartCall = () =>{
        ApiRequestBuilder('oepCommissionsDashboardGet').then(function (results) {
            if (results.data && results.data.length > 0)
            {
                let outStandingCarrierCommission = {};
                let chartData = [];

                results.data.forEach(data =>{

                    if (data.carrier in outStandingCarrierCommission){
                        outStandingCarrierCommission[data.carrier].push(data.total_amount_owed)
                    }
                    else{
                        outStandingCarrierCommission[data.carrier] = [data.total_amount_owed]
                    }
                });

                Object.entries(outStandingCarrierCommission).forEach(([key, value]) => {
                    //To display on the chart, it needs to be in an array of objects with the keys 'name' and 'data'
                    chartData.push({
                        'name': key,
                        'data': value
                    })
                })

                const date = new Date(results.data[0].year + '-' + results.data[0].month +'-02');
                const timeframe = [];

                for (let i = 0; i < 12; i++) {
                    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                    const year = date.getFullYear();
                    timeframe.push(`${month} ${year}`);
                    
                    // Move to the next month
                    date.setMonth(date.getMonth() + 1);
                }

                setCommissionChart({
                    options: {
                        chart: {
                        type: 'bar',
                        stacked: true,
                        },
                        plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                        },
                        xaxis: {
                        categories: timeframe,
                        },
                        legend: {
                        position: 'top',
                        },
                        fill: {
                        opacity: 1,
                        },
                    },
                    series: chartData
                });
            }
        })
    };

    const outStandingCommissionCall = () => {
        ApiRequestBuilder('oepCommissionsOutstandingGet').then(function(results) {
                if (results.data && results.data.length > 0)
                {   
                    setOutStandingCommission(results.data)
                } 
            }
        );
    }

    useEffect(() => {
        commissionChartCall()
        outStandingCommissionCall()
    },[]);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <h4>Commission Dashboard</h4>
                    { commissonChart != false ? 
                        <Col>
                            <Charts options={commissonChart.options} series={commissonChart.series} type="bar" height={350} />
                        </Col>
                    : null}
                </CardBody>
            </Card>
            
            { outStandingCommission.length > 0 ?
                <TableBuilder
                    data = {outStandingCommission}
                    processing = {false}
                    title='Outstanding Commissions'
                    columns = {[
                        {
                            name: 'Category',
                            selector: (row) => row.carrier,
                            sortable: true
                        },
                        {
                            name: 'Total Line',
                            selector: (row) => row.total_line,
                            sortable: true
                        },
                        {
                            name: 'Outstanding Commissions',
                            selector: (row) => row.outstanding,
                            sortable: true
                        }
                    ]}
                />  
            : null}         
        </Fragment>
    )
};

export default CommissionDashboard;