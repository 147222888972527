import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder, ButtonBuilder, CheckBox, Switch } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, setInitialFormValues, uploadPresignedFile } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ShipmentManagerJob = ({jobData, getJobData}) => {
    
    let { jobId, subpage, subpageId } = useParams();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [multiSelect, setMultiSelect] = useState([]);
    const multiSelectRef = useRef([]); // To reference the multiselect data in real time, without having to wait for the state to update and re-render

    const [qualifyingTickets, setQualifyingTickets] = useState([]);
    const [processedTickets, setProcessedTickets] = useState([]);

    const selectAllRows = () => {
        multiSelectRef.current = qualifyingTickets.map(row => row.id).sort();
        setMultiSelect(multiSelectRef.current);
    };

    const deselectAllRows = () => {
        multiSelectRef.current = []
        setMultiSelect([])
    }

    const getQualifyingTickets = () => {

        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'service/tickets',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'summary', 'contactName', 'status', 'board'],
                conditions: 'board/name IN ("Fulfillment", "Returns & Trade Ins", "Customer Support", "Repairs & Warranties") AND status/name IN ("Generate Shipping Label - to client","Generate Shipping Label - from client")'
                // conditions: 'board/name IN ("Fulfillment", "Returns & Trade Ins", "Customer Support", "Repairs & Warranties") AND status/name NOT IN ("Generate Shipping Label - to client","Generate Shipping Label - from client") AND _info/updatedBy = "NexNow"' // Use this condition for testing
            }
        }).then(function(results) {
            deselectAllRows()
            setQualifyingTickets(results.data)
        })
    }

    const getRecentlyProcessedTickets = () => {
        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'service/tickets',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'summary', 'contactName', 'status', 'board', '_info'],
                conditions: 'board/name IN ("Fulfillment", "Returns & Trade Ins", "Customer Support", "Repairs & Warranties") AND status/name NOT IN ("Generate Shipping Label - to client","Generate Shipping Label - from client") AND _info/updatedBy = "NexNow"'
            }
        }).then(function(results) {
            setProcessedTickets(results.data)
        })
    }

    const getTickets = () => {
        getQualifyingTickets()
        getRecentlyProcessedTickets()
    }

    const handleCheckBox = (e, checkboxId) => {
        if(e.target.checked) {
            setMultiSelect([...multiSelect, checkboxId])
            multiSelectRef.current.push(checkboxId)
        } else {
            setMultiSelect(multiSelect.filter((id) => id !== checkboxId))
            multiSelectRef.current = multiSelectRef.current.filter((id) => id !== checkboxId)
        }
    }

    const handleGenerateLabelsSubmit = (formData) => {
        let ticketIds = formData.tickets.split(',').map(Number)
        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobId, actionName: 'generate-shipping-labels' }, {ticketIds:ticketIds, ...formData})
            .then(function(results) {
                ToastBuilder('success', 'Generating Shipping Labels...')
                setTimeout(() => getTickets, 15000)
            }
        )
        deselectAllRows()
    }

    useEffect(() => {   

        document.title = 'Shipment Manager'
        getTickets()

    }, []);

    return (
        <Fragment>
            <Row>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Job Settings</h4>
                            <br/>
                            {jobData && jobData.source_connection ? (<p><strong>Source Connection: </strong>{' ' + jobData.source_connection.name}</p>) : ''}   
                            {jobData && jobData.destination_connection ? (<p><strong>Target Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                         
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Actions</h4>
                            <br/>
                            <ModalButtonBuilder title='Generate Labels' className='m-2 btn-primary' onSubmitLabel='Generate' onSubmit={ multiSelectRef.current.length > 0 }
                                onClick={()=>setInitialFormValues({tickets: multiSelectRef.current.join(', '), ticketCount: multiSelectRef.current.length}, setValue)}
                                body={
                                    <Form id='generate-labels-form'
                                     onSubmit={handleSubmit((formData) => handleGenerateLabelsSubmit(formData)) }>
                                        <p>You have selected <strong style={{color:'green', fontSize:'1.1em'}}>&nbsp;{multiSelectRef.current.length === qualifyingTickets.length && qualifyingTickets.length > 0 ? 'All ' : '' }{multiSelectRef.current.length}&nbsp;</strong> ticket numbers to generate labels for.
                                        <br/>
                                        { multiSelectRef.current.length === qualifyingTickets.length && qualifyingTickets.length > 0 ? '' : (
                                        <>To generate tickets for all qualifying labels,<br/>use the 'Select All' button</>) }
                                         </p><br/>
                                        <RegisteredInputsBuilder
                                            registrator={register}
                                            registratorErrors={errors}
                                            inputs={[
                                                {
                                                    label: 'Ticket #(s):',
                                                    labelSize: 3,
                                                    placeholder: '28872, 17806, 36453',
                                                    name: 'tickets',
                                                    helpText: 'Please use the checkboxes to select the tickets',
                                                    readOnly: true,
                                                    type: 'textarea',
                                                    style: {fieldSizing: 'content', fontFamily: 'monospace'},
                                                },
                                            ]}/>
                                    </Form>
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {qualifyingTickets}
                                processing = {false}
                                title = 'Qualifying Tickets'
                                paginationPerPage = {15}
                                subHeader = {true}
                                columnsToSearch={['id', 'summary', 'board/name', 'status/name', 'contactName']}
                                actions={
                                    <Fragment>
                                        <ButtonBuilder
                                        label={`${multiSelectRef.current.length === qualifyingTickets.length && qualifyingTickets.length > 0 ? 'Deselect All' : 'Select All'}`}
                                        className='btn-primary' onClick={()=>multiSelectRef.current.length === qualifyingTickets.length && qualifyingTickets.length > 0 ? deselectAllRows() : selectAllRows()}/>
                                    </Fragment>
                                }
                                columns = {[
                                    {
                                        name: 'Generate',
                                        selector: (row) =>
                                            <CheckBox reference={multiSelectRef.current} id={`checkbox-${row.id}`}
                                                checked={multiSelect.includes(row.id)} onChange={(e)=>handleCheckBox(e, row.id)}/>,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%',
                                        sortFunction: (a, b) => multiSelect.includes(a.id) - multiSelect.includes(b.id)
                                    },
                                    {
                                        name: 'ID',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '6%'
                                    },
                                    {
                                        name: 'Summary',
                                        selector: (row) => row.summary,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Board',
                                        selector: (row) => row.board.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.status.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Contact',
                                        selector: (row) => row.contactName,
                                        sortable: false,
                                        center: false
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {processedTickets}
                                processing = {false}
                                title = 'Recently Processed Tickets'
                                subHeader = {true}
                                columnsToSearch={['id', 'summary', 'board/name', 'status/name', 'contactName']}
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%'
                                    },
                                    {
                                        name: 'Summary',
                                        selector: (row) => row.summary,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Board',
                                        selector: (row) => row.board.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.status.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Contact',
                                        selector: (row) => row.contactName,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Processed At',
                                        selector: (row) => row._info.lastUpdated.substring(0, 10),
                                        sortable: false,
                                        center: false
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    )
};

export default ShipmentManagerJob;